var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-homologations"
  }, [_c('v-row', {
    attrs: {
      "justify": "space-between",
      "align": "center"
    }
  }, [_c('span', {
    staticClass: "text-wrap display-1 ma-3"
  }, [_vm._v(" " + _vm._s(_vm.$t('HomologationsInProgress')) + " ")]), _c('v-col', {
    attrs: {
      "align": "end"
    }
  }, [_c('v-btn', {
    attrs: {
      "loading": _vm.loading,
      "disabled": _vm.loading,
      "color": "primary",
      "title": _vm.$t('refreshPage')
    },
    on: {
      "click": _vm.refreshPage
    }
  }, [_c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-redo ")])], 1)], 1)], 1), _c('v-divider', {
    staticClass: "mt-2 mb-4"
  }), _vm.homologationList ? _c('v-card', [_c('v-card-title', [_c('DataTableFilter', {
    ref: "filter",
    staticClass: "col-11 col-lg-9 pl-0",
    attrs: {
      "width-of-filters-titles": "2",
      "filters": _vm.filterObject
    },
    on: {
      "update": _vm.updateFilter
    }
  }), _c('v-spacer'), _c('v-text-field', {
    attrs: {
      "append-icon": "fas fa-search",
      "label": "Search",
      "single-line": "",
      "hide-details": ""
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1), _c('v-card-text', [_c('v-data-table', {
    staticClass: "cursorTable",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.homologationList,
      "search": _vm.search,
      "loading": _vm.loadingTable,
      "custom-filter": _vm.customFilter,
      "sort-by": "last_modified",
      "sort-desc": true,
      "footer-props": {
        itemsPerPageOptions: [15, 50, 100, -1]
      }
    },
    on: {
      "click:row": function ($event) {
        return _vm.goToPageItem($event);
      }
    },
    scopedSlots: _vm._u([{
      key: `item.last_modified`,
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('router-link', {
          staticClass: "text-decoration-none",
          attrs: {
            "to": {
              name: 'PartHomologation',
              params: {
                brandUUID: `${item.brand_id._id}`,
                partUUID: `${item._id}`
              }
            }
          }
        }, [item.homologation && item.homologation.lastStatusUpdate ? _c('span', [_vm._v(" " + _vm._s(_vm.$d(new Date(item.homologation.lastStatusUpdate), "long")) + " ")]) : _c('span', [_vm._v(" " + _vm._s(_vm.$d(new Date(item.last_modified), "long")) + " ")])])];
      }
    }, {
      key: `item.homologation.status`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('router-link', {
          staticClass: "text-decoration-none",
          attrs: {
            "to": {
              name: 'PartHomologation',
              params: {
                brandUUID: `${item.brand_id._id}`,
                partUUID: `${item._id}`
              }
            },
            "title": _vm.$t(item.homologation.status)
          }
        }, [item.homologation.status === 'NEW' ? _c('v-chip', {
          staticClass: "primary px-2",
          attrs: {
            "small": "",
            "dense": ""
          }
        }, [_c('span', {
          staticClass: "caption"
        }, [_vm._v("NEW")])]) : item.homologation.status === 'CSG_COMPUTATION' ? _c('v-icon', {
          staticClass: "ml-2",
          attrs: {
            "small": "",
            "color": "info"
          }
        }, [_vm._v(" fab fa-python ")]) : item.homologation.status === 'SUPPLIER_QUOTATION' ? _c('v-icon', {
          staticClass: "ml-2",
          attrs: {
            "small": "",
            "color": "info"
          }
        }, [_vm._v(" fas fa-industry ")]) : item.homologation.status === 'REJECTED' ? _c('v-icon', {
          staticClass: "ml-2",
          attrs: {
            "small": "",
            "color": "error"
          }
        }, [_vm._v(" fa fa-times ")]) : item.homologation.status === 'VALIDATION' ? _c('v-icon', {
          staticClass: "ml-2",
          attrs: {
            "small": "",
            "color": "orange"
          }
        }, [_vm._v(" fas fa-clock ")]) : item.homologation.status === 'READY' ? _c('v-icon', {
          staticClass: "ml-2",
          attrs: {
            "small": "",
            "color": "orange"
          }
        }, [_vm._v(" fa fa-arrow-alt-circle-right ")]) : item.homologation.status === 'ORDERED' ? _c('v-icon', {
          staticClass: "ml-2",
          attrs: {
            "small": "",
            "color": "orange"
          }
        }, [_vm._v(" fa fa-truck ")]) : item.homologation.status === 'TESTING' ? _c('v-icon', {
          staticClass: "ml-2",
          attrs: {
            "small": "",
            "color": "orange"
          }
        }, [_vm._v(" fa fa-vial ")]) : _c('v-icon', {
          attrs: {
            "small": "",
            "color": "primary ml-2"
          }
        }, [_vm._v(" fas fa-times ")])], 1)];
      }
    }, {
      key: `item.homologation.public`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('router-link', {
          staticClass: "text-decoration-none",
          attrs: {
            "to": {
              name: 'PartHomologation',
              params: {
                brandUUID: `${item.brand_id._id}`,
                partUUID: `${item._id}`
              }
            },
            "title": item.homologation.public ? 'CMT' : _vm.$t('PrivateSupplier')
          }
        }, [item.homologation.public ? _c('v-img', {
          staticClass: "mx-auto",
          attrs: {
            "src": require('/src/assets/logo_cut.png'),
            "lazy-src": require('/src/assets/logo_cut.png'),
            "contain": "",
            "width": "30",
            "height": "30"
          }
        }) : item.homologation.public === false ? _c('v-icon', {
          attrs: {
            "small": "",
            "color": "privateSupplier"
          }
        }, [_vm._v(" fas fa-industry ")]) : _vm._e()], 1)];
      }
    }, {
      key: `item.name`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('router-link', {
          staticClass: "text-decoration-none",
          attrs: {
            "to": {
              name: 'PartHomologation',
              params: {
                brandUUID: `${item.brand_id._id}`,
                partUUID: `${item._id}`
              }
            }
          }
        }, [_vm._v(" " + _vm._s(item.name) + " ")])];
      }
    }, {
      key: `item.brand_id.name`,
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_c('router-link', {
          staticClass: "text-decoration-none",
          attrs: {
            "to": {
              name: 'PartHomologation',
              params: {
                brandUUID: `${item.brand_id._id}`,
                partUUID: `${item._id}`
              }
            }
          }
        }, [_vm._v(" " + _vm._s(item.brand_id.name) + " ")])];
      }
    }, {
      key: `item.homologation.technology`,
      fn: function (_ref6) {
        var _item$homologation;
        var item = _ref6.item;
        return [_c('router-link', {
          staticClass: "text-decoration-none",
          attrs: {
            "to": {
              name: 'PartHomologation',
              params: {
                brandUUID: `${item.brand_id._id}`,
                partUUID: `${item._id}`
              }
            }
          }
        }, [(_item$homologation = item.homologation) !== null && _item$homologation !== void 0 && _item$homologation.technology ? _c('v-chip', [_vm._v(" " + _vm._s(_vm.$t(item.homologation.technology)) + " ")]) : _vm._e()], 1)];
      }
    }, {
      key: `item.homologation.material`,
      fn: function (_ref7) {
        var _item$homologation2;
        var item = _ref7.item;
        return [_c('router-link', {
          staticClass: "text-decoration-none",
          attrs: {
            "to": {
              name: 'PartHomologation',
              params: {
                brandUUID: `${item.brand_id._id}`,
                partUUID: `${item._id}`
              }
            }
          }
        }, [(_item$homologation2 = item.homologation) !== null && _item$homologation2 !== void 0 && _item$homologation2.material ? _c('v-chip', [_vm._v(" " + _vm._s(_vm.$t(item.homologation.material)) + " ")]) : _vm._e()], 1)];
      }
    }, {
      key: `item.dna.typeOfProduction`,
      fn: function (_ref8) {
        var item = _ref8.item;
        return [_c('router-link', {
          staticClass: "text-decoration-none",
          attrs: {
            "to": {
              name: 'PartHomologation',
              params: {
                brandUUID: `${item.brand_id._id}`,
                partUUID: `${item._id}`
              }
            }
          }
        }, [item.dna.typeOfProduction === 'prototype' ? _c('v-img', {
          staticClass: "mx-auto",
          attrs: {
            "src": require("../../../public/img/icons/modeling.svg"),
            "alt": "prototype icon",
            "max-width": "30px",
            "max-height": "30px"
          }
        }) : _vm._e(), item.dna.typeOfProduction === 'functionalPart' ? _c('v-img', {
          staticClass: "mx-auto",
          attrs: {
            "src": require("../../../public/img/icons/functionalProduction2.svg"),
            "alt": "functional production icon",
            "max-width": "30px",
            "max-height": "30px"
          }
        }) : _vm._e()], 1)];
      }
    }, {
      key: `item.action`,
      fn: function (_ref9) {
        var item = _ref9.item;
        return [_c('v-btn', {
          staticClass: "white--text",
          attrs: {
            "color": "primary",
            "to": {
              name: 'PartHomologation',
              params: {
                brandUUID: `${item.brand_id._id}`,
                partUUID: `${item._id}`
              }
            },
            "title": _vm.$t('view')
          }
        }, [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v(" fas fa-eye ")])], 1)];
      }
    }], null, true)
  })], 1)], 1) : _c('p', {
    staticClass: "col-10 m-0 text-center",
    staticStyle: {
      "position": "fixed",
      "top": "30%",
      "font-size": "2rem"
    }
  }, [_c('v-icon', {
    staticClass: "pr-4",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-dna ")]), _vm._v(" " + _vm._s(_vm.$t('noHomologationInProgress')) + " ")], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }