<template lang="html" src="./pageHomologations.template.vue"></template>

<style lang="scss" src="./pageHomologations.scss"></style>

<script>
import { ApiErrorParser, CustomFilterForSearchDatatable, DataTableFilter } from '@cloudmanufacturingtechnologies/portal-components';

const i18nData = require('./pageHomologations.i18n');

export default {
  name: 'PageHomologations',
  components: { DataTableFilter },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      search: '',
      headers: [],
      homologationList: [],
      statusFilter: [],
      typeOfProductionFilter: [],
      typeFilter: [],
      filterObject: {
        status: [
          {
            name: this.$t('NewHomologation'),
            value: 'NEW',
            img: 'new.png',
            number: 0,
          },
          {
            name: this.$t('SerialNumberGeneration'),
            value: 'CSG_COMPUTATION',
            icon: 'fab fa-python',
            iconColor: 'info',
            number: 0,
          },
          {
            name: this.$t('SupplierQuotation'),
            value: 'SUPPLIER_QUOTATION',
            icon: 'fas fa-industry',
            iconColor: 'info',
            number: 0,
          },
          {
            name: this.$t('Rejected'),
            value: 'REJECTED',
            icon: 'fas fa-times',
            iconColor: 'error',
            number: 0,
          },
          {
            name: this.$t('AwaitingBCMValidation'),
            value: 'VALIDATION',
            icon: 'fas fa-clock',
            iconColor: 'orange',
            number: 0,
          },
          {
            name: this.$t('READY'),
            value: 'READY',
            icon: 'fas fa-arrow-alt-circle-right',
            iconColor: 'orange',
            number: 0,
          },
          {
            name: this.$t('ORDERED'),
            value: 'ORDERED',
            icon: 'fas fa-truck',
            iconColor: 'orange',
            number: 0,
          },
          {
            name: this.$t('TESTING'),
            value: 'TESTING',
            icon: 'fas fa-vial',
            iconColor: 'orange',
            number: 0,
          },
        ],
        typeOfProduction: 
          [{
            name: this.$t('Prototype'),
            value: 'prototype', 
            img: 'modeling.svg', 
            solo: true, 
            number: 0,
          }, {
            name: this.$t('FunctionalPart'),
            value: 'functionalPart', 
            img: 'functionalProduction2.svg', 
            solo: true, 
            number: 0,
          }],
        type: [
          {
            name: 'CMT',
            value: 'public', 
            img: 'logo_cut.png',
            number: 0,
            selected: true
          },
          {
            name: this.$t('PrivateSupplier'),
            value: 'private',
            iconColor: 'privateSupplier',
            icon: 'fas fa-industry',
            number: 0,
          }
        ]
      },
      loading: false,
      loadingTable: false,
    };
  },
  created() {
    this.getAllHomologations();
  },
  mounted() {
    this.headers = [
      { 
        text: this.$t('status'),
        value: 'homologation.status',
        filter: (value) => {
          if (this.statusFilter.length === 0) return true;
          if (this.statusFilter.includes(value)) return true;
        }
      },
      {
        text: this.$t('Type'),
        value: 'homologation.public',
        width: '100px',
        align: 'center',
        sortable: false,
        filter: (value) => {
          if (this.typeFilter.length === 0) return true;
          if ((value && this.typeFilter.includes('public'))
            || (value === false && this.typeFilter.includes('private'))
          ) {
            return true;
          };
        },
      },
      { text: this.$t('partName'), value: 'name' },
      { text: this.$t('brandName'), value: 'brand_id.name' },
      { text: this.$t('Technology'), value: 'homologation.technology'},
      { text: this.$t('Material'), value: 'homologation.material'},
      {
        text: this.$t('typeOfProduction'),
        align: 'center',
        value: 'dna.typeOfProduction',
        filter: (value) => {
          if (this.typeOfProductionFilter.length === 0) return true;
          if (this.typeOfProductionFilter.includes(value)) return true;
        }
      },
      { text: this.$t('Supplier'), value: 'homologation.supplier.name'},
      { text: this.$t('LastModification'), value: 'last_modified', align: 'center' },
      { text: this.$t('action'), value: 'action', align: 'center' },
    ];
  },
  methods: {
    customFilter(value, search, item) {
      return CustomFilterForSearchDatatable.customFilter(value, search, this);
    },
    updateFilter(updatedFilter) {
      this.statusFilter = updatedFilter.status;
      this.typeOfProductionFilter = updatedFilter.typeOfProduction;
      this.typeFilter = updatedFilter.type;
    },
    getAllHomologations() {
      this.loadingTable = true;
      /**
       * GET ALL QUOTES
       */
      this.$apiInstance
        .getAllHomologations()
        .then(
          (data) => {
            this.homologationList = data;
            /**
             * Reset Filter Object
             */
            for (const key in this.filterObject) {
              for (const obj of this.filterObject[key]) {
                obj.number = 0;
              }
            }
            this.homologationList.forEach(part => {
              if(!part.homologation) {
                part.homologation = {
                  status: 'NEW'
                };
              }
              this.filterObject.status.find(x => x.value === part.homologation.status).number++;
              if(part.dna?.typeOfProduction) {
                this.filterObject.typeOfProduction.find(x => x.value === part.dna.typeOfProduction).number++;
              }
              const filter = this.filterObject.type.find(
                x => (x.value === 'public' && part.homologation?.public)
                  || (x.value === 'private' && part.homologation?.public === false)
              );
              if(filter) {
                filter.number++;
              }
            });
          },
          (error) => {
            /**
             * ERROR GET ALL QUOTES
             */
            ApiErrorParser.parse(error);
          }
        )
        .finally(() => {
          this.loadingTable = false;
          this.loading = false;
        });
    },
    refreshPage() {
      this.loading = true;
      this.getAllHomologations();
    },
    goToPageItem(part) {
      this.$router.push({name: 'PartHomologation', params: { brandUUID: `${part.brand_id._id}`, partUUID: `${part._id}`}});
    },
  },
};
</script>
